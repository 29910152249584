import { Version } from './version.model';

export const version: Version = {
  build: {
    time: '2024-08-21T09:02:18+0000',
  },
  git: {
    branchName: 'master',
    commitHash: '069ec60cb0bf72281ee751aa2c415ef89ae96ceb',
  },
};
