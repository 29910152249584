export enum TemplateNameType {
  vocabulary = 'vocabulary',
  project = 'project',
  pictures = 'pictures',
  document = 'document',
  video = 'video',
  iframe = 'iframe',
  proca_data_quality = 'proca-data-quality',
  properties = 'properties',
  costcenter = 'costcenter',
}
